import React, { useState, useEffect, useMemo, useRef } from 'react';
import InputMask from 'react-input-mask';
import { Flex, Text, Dropdown, Checkbox, Input, SearchIcon, FlexItem, RadioGroup } from '@fluentui/react-northstar';
import Lockr from 'lockr';
import { Desire, Colors, PresenceAvailability, StorageKeys, DefaultTimeSpan, logging } from 'utils';
import { Paginator } from 'containers';
import AdvancedTable, { stringCellComparator } from 'components/AdvancedTable';
import { useIntl } from 'react-intl';
import AgentAvatar from 'components/AgentAvatar';
import Timer from 'components/Timer';
import { SupervisorAgentDto, SupervisorQueueDto, SupervisorQueuesData } from 'utils/domain/extended/supervisorDto';
import { PagedResultDto } from 'utils/domain/extended/pagedResultDto';
import ChoiceGroup, { IChoiceGroupItem } from '../../../components/ChoiceGroup';
import { DateFromBackendUtc } from 'utils/helpers/date-help';
import { supervisorService } from 'services/extendedSupervisor/extendedSupervisorService';
import { Filter, Sort, SortDirectionS3 } from 'services/extendedSupervisor/models/filters';
import { InternalPresenceDto } from 'services/extendedSupervisor/models/internalPresenceDto';
import { PageStateData } from 'services/extendedSupervisor/models/pageStateData';
import { AgentMemberUpdatedDto } from 'services/extendedSupervisor/models/agentMemberUpdatedDto';
import { GeneralHelper, ValidationHelper } from 'utils/helpers';
import { useOperationListenerEffect, useSubjectListenerEffect } from 'utils/helpers/listenerHook';
import { BackendNotification } from 'utils/enums-s3';
import { socketQueue } from 'services/queue';
import { AgentUpdatedData } from 'services/extendedSupervisor/models/agentUpdatedData';
import UserPresenceView from 'containers/PresenceView/UserPresenceView';
import { AgentHasClientDto } from 'services/extendedSupervisor/models/AgentHasClientDto';
import './ExtendedSupervisorView.scss';

interface QueueDropdownItem {
    id: string;
    title: string;
    header: string;
    item: SupervisorQueueDto;
}

export const SupervisorView = () => {
    const intl = useIntl();
    const numberOfRowsPerPage = useRef<number>(10);
    const showStatusTimer = useRef<boolean>(false);
    const userId = useRef<string>(Lockr.get(StorageKeys.UserId));

    const [queuesDropdownItems, setQueueDropdownItems] = useState<QueueDropdownItem[]>([]);
    const [agentsPage, setAgentsPage] = useState<PagedResultDto<SupervisorAgentDto>>(new PagedResultDto<SupervisorAgentDto>());
    const [pageState, setPageState] = useState<PageStateData>(new PageStateData());
    const [searchText, setSearchText] = useState<string>(pageState.search);
    const totalPages = useMemo<number>(() => Math.ceil(agentsPage.TotalCount / numberOfRowsPerPage.current) || 1, [agentsPage]);
    const [selectedQueue, setSelectedQueue] = useState<SupervisorQueueDto | null>(null);
    const [refresh, setRefresh] = useState<number>(Date.now());
    const [useDefinedEstimatedWaitTime, setUseDefinedEstimatedWaitTime] = useState<boolean>(false);
    const [definedEstimatedWaitTime, setDefinedEstimatedWaitTime] = useState<string>(DefaultTimeSpan);
    const [definedEstimatedWaitTimeError, setDefinedEstimatedWaitTimeError] = useState(false);
    const [persistEstimatedWaitTimeFields, setPersistEstimatedWaitTimeFields] = useState(0);

    const intervalMs: number = 10000;

    const styleClasses = {
        smallerGrow: "smaller-grow",
        bigGrow: "big-grow",
        smallGrow: "small-grow",
        smallestGrow: "smallest-grow",
    };

    const desireToColorMap: Record<string, Colors> = {
        [Desire.Low]: Colors.Green,
        [Desire.Normal]: Colors.Gray,
        [Desire.High]: Colors.Red,
    };

    const colorToDesireMap: Record<string, Desire> = {
        [Colors.Green]: Desire.Low,
        [Colors.Gray]: Desire.Normal,
        [Colors.Red]: Desire.High,
    };

    useEffect(() => {
        fetchSupervisorViewData();

        // refresh supervisor data every 10 seconds CC4ALL-4640
        const interval = setInterval(() => setRefresh(Date.now()), intervalMs);

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        fetchAgentsViewData();
    }, [refresh, pageState]);

    useEffect(() => {
        updateDefinedEstimatedWaitTime();
    }, [persistEstimatedWaitTimeFields]);

    useOperationListenerEffect((agentsUpdated: AgentMemberUpdatedDto) => {
        updateAgentsFromUpdates(agentsUpdated);
    }, BackendNotification.SupervisorAgentsUpdated);

    useOperationListenerEffect((internalPresence: InternalPresenceDto) => {
        updateInternalPresence(internalPresence);
    }, BackendNotification.InternalPresenceChanged);

    useOperationListenerEffect((agentHasClient: AgentHasClientDto) => {
        updateAgentHasClient(agentHasClient);
    }, BackendNotification.UserTeamsClientChanged);

    useOperationListenerEffect((queueChange: SupervisorQueueDto) => {
        setQueueDropdownItems(prev => {
            const queueIndex = prev.findIndex(x => x.id === queueChange.Id);

            if (queueIndex === -1) {
                return prev;
            }

            const newArray = [...prev];
            newArray[queueIndex].title = queueChange.Name;
            newArray[queueIndex].item = queueChange;

            if (selectedQueue?.Id === queueChange.Id) {
                setSelectedQueue(queueChange);
            }

            return newArray;
        });
    }, BackendNotification.QueueChanged);

    useOperationListenerEffect((queueChange: SupervisorQueueDto) => {
        setQueueDropdownItems(prev => {
            const queueIndex = prev.findIndex(x => x.id === queueChange.Id);

            if (queueIndex === -1) {
                return prev;
            }

            const newArray = [...prev];
            newArray[queueIndex].title = queueChange.Name;
            newArray[queueIndex].item = { ...newArray[queueIndex].item, ...queueChange };

            if (selectedQueue?.Id === queueChange.Id) {
                setEstimatedWaitTimeFields(newArray[queueIndex].item);
                setSelectedQueue(newArray[queueIndex].item);
            }

            return newArray;
        });
    }, BackendNotification.QueueEstimatedWaitTimesChanged);

    useSubjectListenerEffect((data: any) => {
        checkMemberResyncQueues(data);
    }, socketQueue.listenerNotifyQueueUserMembershipChange.received);

    useSubjectListenerEffect((data: any) => {
        checkMemberResyncQueues(data);
    }, socketQueue.listenerNotifyQueueUserMembershipRemove.received);

    const fetchAgentsViewData = () => {
        if (!selectedQueue) {
            return;
        }

        Lockr.set(StorageKeys.SupervisorQueueSelected, selectedQueue.Id);
        fetchQueueAgents(selectedQueue.Id, pageState);
    }

    const fetchSupervisorViewData = () => {
        supervisorService.getQueues()
            .then((data: SupervisorQueuesData) => {
                const queues = data.Queues;
                showStatusTimer.current = data.ShowStatusTimer;
                numberOfRowsPerPage.current = data.PageSize;

                if (!queues) {
                    return;
                }

                const mappedQueues = [...queues]
                    .sort((a: SupervisorQueueDto, b: SupervisorQueueDto) => a.Name.localeCompare(b.Name))
                    .map((x: SupervisorQueueDto) => ({
                        id: x.Id,
                        title: x.Name,
                        header: x.Name,
                        item: x
                    } as QueueDropdownItem));

                setQueueDropdownItems(mappedQueues);

                const selectedQueueId = Lockr.get<string>(StorageKeys.SupervisorQueueSelected);
                const selectedQueueOrDefault = (!!selectedQueueId && queues.find(x => x.Id === selectedQueueId)) || queues[0];

                selectNewQueue(selectedQueueOrDefault);
            });
    }

    const checkMemberResyncQueues = (data: any) => {
        if (!data || !data.UserIds) {
            return;
        }

        if (data.UserIds.includes(userId.current)) {
            fetchSupervisorViewData();
        }
    }

    const fetchQueueAgents = (queueId: string, page: PageStateData) => {
        supervisorService.getAgentsData(queueId, page)
            .then((pageResult: PagedResultDto<SupervisorAgentDto>) => setAgentsPage(pageResult));
    }

    const updateAgentsFromUpdates = (data: AgentMemberUpdatedDto) => {
        if (!selectedQueue || data.QueueId !== selectedQueue.Id) {
            return;
        }

        setAgentsPage(prev => {
            if (!!data.Deleted && prev.Items.some(x => data.Deleted.includes(x.Id))) {
                reloadFirstPage();

                return prev;
            }

            if (!data.Updated) {
                return prev;
            }

            const newItems = [...prev.Items];

            for (const agent of data.Updated) {
                const agentIndex = newItems.findIndex(x => x.Id === agent.Id);

                if (agentIndex === -1 || containsJoinedFilter(newItems[agentIndex], agent)) {
                    reloadFirstPage();

                    return prev;
                }

                newItems[agentIndex] = {
                    ...newItems[agentIndex],
                    IsJoined: agent.IsJoined ?? false,
                    Desire: agent.Desire != 0 ? agent.Desire : newItems[agentIndex].Desire
                };
            }

            return { ...prev, Items: newItems };
        });
    }

    const updateInternalPresence = (data: InternalPresenceDto) => {
        if (!selectedQueue) {
            return;
        }

        setAgentsPage(prev => {
            const agentIndex = prev.Items.findIndex(x => x.Id === data.Presence.Id);

            if (agentIndex === -1 || containsHasClient(prev.Items[agentIndex], data)) {
                reloadFirstPage();

                return prev;
            }

            const newItems = [...prev.Items];
            newItems[agentIndex] = {
                ...newItems[agentIndex],
                Presence: data.Presence,
                HasClient: data.HasClient,
                Reason: data.Reason ?? '',
                LastHandled: !!data.LastHandledTimes ? data.LastHandledTimes[selectedQueue.Id] : '',
            };

            return { ...prev, Items: newItems };
        });
    }

    const containsJoinedFilter = (agent: SupervisorAgentDto, update: AgentUpdatedData): boolean => {
        return pageState.filter === Filter.Joined && update.IsJoined !== agent.IsJoined;
    }

    const containsHasClient = (agent: SupervisorAgentDto, update: InternalPresenceDto): boolean => {
        if (pageState.filter === Filter.HasClient && update.HasClient !== agent.HasClient) {
            return true;
        }
        if (pageState.filter !== Filter.Online) {
            return false;
        }

        const offlines = [PresenceAvailability.None, PresenceAvailability.Offline];

        const updatedInOffline = offlines.includes(update.Presence.SimplifiedAvailability ?? PresenceAvailability.None);
        const agentInOffline = offlines.includes(agent.Presence.SimplifiedAvailability ?? PresenceAvailability.None);

        return updatedInOffline != agentInOffline;
    }

    const reloadFirstPage = () => {
        setPageState({ ...pageState, page: 0 });
    }

    const updateAgentHasClient = (agentHasClient: AgentHasClientDto) => {
        if (!selectedQueue) {
            return;
        }

        setAgentsPage(prev => {
            const agentIndex = prev.Items.findIndex(x => x.Id === agentHasClient.UserId);

            if (agentIndex === -1) {
                return prev;
            }

            const newItems = [...prev.Items];
            newItems[agentIndex] = {
                ...newItems[agentIndex],
                HasClient: agentHasClient.HasCC4TeamsClient,
            };

            return { ...prev, Items: newItems };
        });
    }


    const mapAgentItemToTable = (agent: SupervisorAgentDto) => {
        const items: { content: JSX.Element | string, key: string, className: string }[] = [
            {
                content: getAvatarAndName(agent),
                key: `${agent.Id}-agent`,
                className: styleClasses.bigGrow
            },
            {
                content: !!agent.LastHandled ? GeneralHelper.formatAMPM(DateFromBackendUtc(agent.LastHandled)) : "",
                key: `${agent.Id}-lastHandled`,
                className: styleClasses.smallerGrow
            },
            {
                content: GeneralHelper.getStatusText(agent.Reason ?? '', intl),
                key: `${agent.Id}-reason`,
                className: styleClasses.bigGrow
            },
            {
                content: intl.formatMessage({ id: `SupervisorView.${agent.HasClient ? "Yes" : "No"}` }),
                key: `${agent.Id}-hasClient`,
                className: styleClasses.smallGrow,
            },
            {
                content:
                    <RadioGroup
                        defaultCheckedValue={Colors.Gray}
                        checkedValue={desireToColorMap[agent.Desire]}
                        items={[Colors.Green, Colors.Gray, Colors.Red].map(color => ({
                            key: color,
                            value: color,
                            name: color,
                            'aria-label': color,
                            variables: {
                                indicatorColorDefault: color,
                                indicatorBackgroundColorChecked: color,
                                indicatorBorderColorDefaultHover: color,
                                margin: "0px",
                                padding: "0px",
                            },
                        }))}
                        onCheckedValueChange={(e, props) => {
                            handleDesireState(agent, colorToDesireMap[props?.value?.toString() ?? Colors.Gray]);
                        }}
                    />,
                key: `${agent.Id}-desire`,
                className: styleClasses.smallestGrow
            },
            {
                content: <Checkbox checked={agent.IsJoined} onChange={() => handleAgentJoinedState(agent)} />,
                key: `${agent.Id}-isJoined`,
                className: styleClasses.smallestGrow
            },
        ];

        if (showStatusTimer.current) {
            const statusTimer = {
                content: agent.Presence?.Availability !== "Offline" && !!agent.Presence?.UpdatedAt ? <Timer startDate={agent.Presence?.UpdatedAt} /> : "",
                key: `${agent.Id}-statusTime`,
                className: styleClasses.smallerGrow
            };
            items.splice(2, 0, statusTimer);
        }

        return {
            key: agent.Id,
            upn: agent.Upn,
            items: items
        }
    };

    const handleAgentJoinedState = (agent: SupervisorAgentDto) => {
        const selectedQueueId = selectedQueue?.Id;

        if (!selectedQueueId) {
            return;
        }

        setAgentsPage(prev => {
            const agentIndex = prev.Items.findIndex(x => x.Id === agent.Id);
            const newItems = [...prev.Items];
            newItems[agentIndex] = { ...agent, IsJoined: !agent.IsJoined };

            return { ...prev, Items: newItems };
        });

        const shouldFetchData = pageState.filter === Filter.Joined;

        supervisorService.updateJoinedStatus(agent.Id, selectedQueueId, !agent.IsJoined)
            .then(() => {
                if (shouldFetchData) {
                    fetchQueueAgents(selectedQueueId, pageState);
                }
            })
            .catch(() => {
                setAgentsPage(prev => {
                    const agentIndex = prev.Items.findIndex(x => x.Id === agent.Id);
                    const newItems = [...prev.Items];
                    newItems[agentIndex] = { ...agent, IsJoined: agent.IsJoined };

                    return { ...prev, Items: newItems };
                });
            });
    }

    function handleDesireState(agent: SupervisorAgentDto, desire: number) {
        const selectedQueueId = selectedQueue?.Id;

        if (!selectedQueueId) {
            return;
        }

        setAgentsPage(prev => {
            const agentIndex = prev.Items.findIndex(x => x.Id === agent.Id);
            const newItems = [...prev.Items];
            newItems[agentIndex] = { ...agent, Desire: desire };

            return { ...prev, Items: newItems };
        });

        supervisorService.updateDesireState(agent.Id, selectedQueueId, desire)
            .catch(() => {
                setAgentsPage(prev => {
                    const agentIndex = prev.Items.findIndex(x => x.Id === agent.Id);
                    const newItems = [...prev.Items];
                    newItems[agentIndex] = { ...agent, Desire: agent.Desire };

                    return { ...prev, Items: newItems };
                });
            });
    }

    const getAvatarAndName = (agent: SupervisorAgentDto) => {
        return agent.Presence.Availability === "Offline"
            ? <Flex vAlign="center">
                <AgentAvatar
                    styles={{ marginRight: '0.5rem', }}
                    status={agent.Presence?.Availability}
                    name={agent.Name}
                />
            </Flex>
            : <UserPresenceView
                key={`${agent.Id}:${selectedQueue?.Id}:${agent.Presence.Availability}:${agent.Reason}`}
                sip={agent.Upn}
                id={agent.Id}
                queueRef={selectedQueue?.Id}
                name={agent.Name}
                availability={agent.Presence.Availability}
                activity={agent.Reason}
            />
    }

    const columnsMembers = useMemo(() => {
        const items = [
            {
                title: intl.formatMessage({ id: "SupervisorView.Agent" }),
                key: 'Agent',
                name: 'SupervisorViewAgentColumn',
                cellComparator: stringCellComparator,
                className: styleClasses.bigGrow
            },
            {
                title: intl.formatMessage({ id: "SupervisorView.LastHandled" }),
                key: 'LastHandledTime',
                name: 'SupervisorLastHandledColumn',
                className: styleClasses.smallerGrow
            },
            {
                title: intl.formatMessage({ id: "SupervisorView.Reason" }),
                key: 'Reason',
                name: 'ReasonColumn',
                className: styleClasses.bigGrow
            },
            {
                title: intl.formatMessage({ id: "SupervisorView.HasClient" }),
                key: 'HasClient',
                name: 'HasClientColumn',
                className: styleClasses.smallGrow
            },
            {
                title: intl.formatMessage({ id: "SupervisorView.Desire" }),
                key: 'Desire',
                name: 'DesireColumn',
                className: styleClasses.smallestGrow
            },
            {
                title: intl.formatMessage({ id: "SupervisorView.Joined" }),
                key: 'Joined',
                name: 'IsJoinedColumn',
                className: styleClasses.smallestGrow
            },
        ];

        if (showStatusTimer.current) {
            const statusTimer = {
                title: intl.formatMessage({ id: "SupervisorView.StatusTimer" }),
                key: 'StatusTimer',
                name: 'SupervisorStatusTimerColumnS3',
                cellComparator: stringCellComparator,
                className: styleClasses.smallerGrow
            };
            items.splice(2, 0, statusTimer);
        }

        return items;
    }, [queuesDropdownItems]);

    const mappedFilters = useMemo<IChoiceGroupItem[]>(() => {
        return [
            {
                key: Filter.Online.toString(),
                label: intl.formatMessage({ id: "SupervisorView.Online" }),
            },
            {
                key: Filter.HasClient.toString(),
                label: intl.formatMessage({ id: "SupervisorView.HasClient" }),
            },
            {
                key: Filter.Joined.toString(),
                label: intl.formatMessage({ id: "SupervisorView.Joined" }),
            },
        ];
    }, []);

    const sortHandler = (columnName: string, direction: number) => {
        const sort = columnName === "SupervisorViewAgentColumn" ? Sort.Agent : Sort.StatusTime;
        const sortDirection = direction === -1 ? SortDirectionS3.Desc : SortDirectionS3.Asc;

        setPageState(prev => ({ ...prev, sort: sort, sortDirection: sortDirection }))
    }

    const onSearchKeyDown = (code: string, search: string) => {
        const searchTextLength = search.length;

        if (code === "Enter" && searchTextLength > 2) {
            setPageState(prev => ({ ...prev, search: search }));
        }
    }

    const onSearchChange = (search: string) => {
        setSearchText(search);

        if (search.length === 0) {
            setPageState(prev => ({ ...prev, search: search }));
        }
    }

    const selectNewQueue = (queue: SupervisorQueueDto) => {
        if (selectedQueue?.Id == queue.Id) {
            return;
        }
        setEstimatedWaitTimeFields(queue);
        setSelectedQueue(queue);
        setSearchText('');
        setPageState(new PageStateData());
    }

    const setEstimatedWaitTimeFields = (queue: SupervisorQueueDto) => {
        setUseDefinedEstimatedWaitTime(queue.UseDefinedEstimatedWaitTime);
        setDefinedEstimatedWaitTime(queue.DefinedEstimatedWaitTime);
        setDefinedEstimatedWaitTimeError(false);
    }

    const toggleUseDefinedEstimatedWaitTime = () => {
        const newValueOfUseDefinedEstimatedWaitTime = !useDefinedEstimatedWaitTime;
        if (newValueOfUseDefinedEstimatedWaitTime) {
            setDefinedEstimatedWaitTime(selectedQueue?.AverageEstimatedWaitTime || DefaultTimeSpan);
        } else {
            setDefinedEstimatedWaitTimeError(false);
            setDefinedEstimatedWaitTime(DefaultTimeSpan);
        }
        setUseDefinedEstimatedWaitTime(newValueOfUseDefinedEstimatedWaitTime);
        setPersistEstimatedWaitTimeFields(Math.random());
    }

    const setQueueDefinedEstimatedWaitTime = (event: any) => {
        setDefinedEstimatedWaitTime(event.target.value);

        if (!ValidationHelper.isValidTimeSpan(event.target.value)) {
            setDefinedEstimatedWaitTimeError(true);
        } else {
            setDefinedEstimatedWaitTimeError(false);
            setPersistEstimatedWaitTimeFields(Math.random());
        }
    }

    const cancelChangingTheValueIfInvalid = (event: any) => {
        if (!ValidationHelper.isValidTimeSpan(event.target.value)) {
            setDefinedEstimatedWaitTime(selectedQueue?.DefinedEstimatedWaitTime || DefaultTimeSpan);
            setDefinedEstimatedWaitTimeError(false);
        }
    }

    const updateDefinedEstimatedWaitTime = () => {
        const selectedQueueId = selectedQueue?.Id;

        if (!selectedQueueId) {
            return;
        }

        supervisorService.updateQueueDefinedEstimatedWaitTime(selectedQueueId, useDefinedEstimatedWaitTime, definedEstimatedWaitTime)
            .catch(() => {
                logging.errorHandler.next("SupervisorCallsView.ErrorOnUpdatingEstimatedWaitTime")
            });
    }
    const mappedAgents = useMemo(() => agentsPage.Items.map(x => mapAgentItemToTable(x)), [agentsPage]);
    return (<Flex column key="SupervisorViewKey">
        <Flex fill gap="gap.small" vAlign="center">
            <Text content={intl.formatMessage({ id: "SupervisorView.Header" })} weight="bold" />

            <Dropdown
                inverted
                items={queuesDropdownItems}
                placeholder={selectedQueue?.Name}
                value={queuesDropdownItems.find(x => x.id === selectedQueue?.Id)}
                onChange={(e, data) => selectNewQueue((data.value as QueueDropdownItem)?.item)}
            />
            <Checkbox
                disabled
                label={intl.formatMessage({ id: "SupervisorCallsView.OfferCallsToAgentsWithoutClient" })}
                checked={!!selectedQueue?.OfferCallsToAgentsWithoutClient}
                styles={{ cursor: "default", color: "rgb(37, 36, 35)" }}
            />
            <FlexItem push>
                <Flex
                    vAlign="center"
                    gap="gap.smaller"
                >
                    <Input
                        style={{ marginLeft: "10px" }}
                        icon={<SearchIcon size="small" />}
                        inverted
                        iconPosition="start"
                        value={searchText}
                        placeholder={intl.formatMessage({ id: "ContactsView.Search" })}
                        onChange={(e: any) => onSearchChange(e.target.value)}
                        onKeyDown={(e: any) => onSearchKeyDown(e.key, e.target.value.trim())}
                    />
                    <ChoiceGroup
                        toggle
                        push
                        items={mappedFilters}
                        value={pageState.filter.toString()}
                        onChange={(x: IChoiceGroupItem, selected: boolean) => {
                            setPageState(prev => ({ ...prev, page: 0, filter: selected ? x.key as Filter : Filter.None }))
                        }}
                    />
                </Flex>
            </FlexItem>
        </Flex>
        <Flex fill gap="gap.small" vAlign="center" className='second-row'>
            <Text content={intl.formatMessage({ id: "SupervisorCallsView.EstimatedWaitTime" })} className='row-label' />
            {!useDefinedEstimatedWaitTime &&
                <Input
                    disabled
                    inverted
                    value={selectedQueue?.AverageEstimatedWaitTime}
                />}
            {useDefinedEstimatedWaitTime &&
                <InputMask mask="99:99:99" value={definedEstimatedWaitTime} onChange={setQueueDefinedEstimatedWaitTime} onBlur={cancelChangingTheValueIfInvalid} className='estimated-wait-time-input'>
                    {(inputProps: any) => <Input {...inputProps} inverted error={definedEstimatedWaitTimeError} />}
                </InputMask>

            }
            <Checkbox
                label={intl.formatMessage({ id: "SupervisorCallsView.UseDefinedEstimatedWaitTime" })}
                checked={useDefinedEstimatedWaitTime}
                styles={{ color: "rgb(37, 36, 35)" }}
                onChange={toggleUseDefinedEstimatedWaitTime}
            />

        </Flex>
        <AdvancedTable
            columns={columnsMembers}
            rows={mappedAgents}
            sortHandler={sortHandler}
            label="Supervisor"
            className="supervisor-table"
        />
        {
            totalPages > 1 && <Paginator
                currentPage={pageState.page + 1}
                totalPages={totalPages}
                onChange={(page: number) => setPageState(prev => ({ ...prev, page: page - 1 }))}
            />
        }
    </Flex>);
};

export default SupervisorView;